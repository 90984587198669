// function formatTime(time) {
//     let formattedTime = '';
//
//     if (/\d\d:\d\d:\d\d/.test(time)) {
//         const [h, m] = time.split(':');
//         const amPm = h < 12 ? 'AM' : 'PM';
//
//         formattedTime = `${h % 12}:${m} ${amPm}`;
//     }
//
//     return formattedTime;
// }
//
// function formatDate(date) {
//     const dayjs = require('dayjs');
//
//     return dayjs(date).format('DD/MM/YYYY');
// }
//
// function formatDateTime(datetime) {
//     const dayjs = require('dayjs');
//
//     return dayjs(datetime).format('DD/MM/YYYY HH:mm');
// }
//
// function isNumeric(value) {
//     return value !== null && value !== undefined && value.toString().trim() !== '' && !isNaN(value);
// }
//
// function isPositiveNumber(value) {
//     return isNumeric(value) && value > 0;
// }
//
// function isZeroOrPositive(value) {
//     return value === '' || (isNumeric(value) && value >= 0);
// }
//
// function formatCurrency(value) {
//     if (!isNumeric(value)) {
//         value = 0;
//     }
//
//     return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
// }

function formatMoney(value, decimals, separators) {
    decimals = decimals >= 0 ? parseInt(decimals, 0) : 2;
    separators = separators || ['.', "'", ','];

    let number = (parseFloat(value) || 0).toFixed(decimals);

    if (number.length <= (4 + decimals)) {
        return number.replace('.', separators[separators.length - 1]);
    }

    let parts = number.split(/[-.]/);

    value = parts[parts.length > 1 ? parts.length - 2 : 0];

    let result = value.substr(value.length - 3, 3) + (parts.length > 1 ?
        separators[separators.length - 1] + parts[parts.length - 1] : '');
    let start = value.length - 6;
    let idx = 0;

    while (start > -3) {
        result = (start > 0 ? value.substr(start, 3) : value.substr(0, 3 + start))
            + separators[idx] + result;
        idx = (++idx) % 2;
        start -= 3;
    }

    return (parts.length === 3 ? '-' : '') + result;
}

function downloadAttachment(response, fileName) {
    const href = window.URL.createObjectURL(response.data);

    const anchorElement = document.createElement('a');

    anchorElement.href = href;

    const contentDisposition =
        response.headers['content-disposition'];

    if (contentDisposition) {
        const fileNameMatch =
            contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
        }
    }

    anchorElement.download = fileName;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
}

function isNumeric(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '' && !isNaN(value);
}

module.exports = {
    formatMoney,
    downloadAttachment,
    isNumeric
};